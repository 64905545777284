import React from 'react'
import { Route } from 'react-router-dom'

/**
 * Returns a path string with all `/:optional?` params REMOVED
 *
 * @param {String} path
 * @returns {String} path without optional params at end
 */

const createRoutePathWithoutOptionalParams = path => {
  const sections = path.split('/')

  for (let i = 0; i < sections.length; i++) {
    if (sections[i].includes('?')) {
      return sections.slice(0, i).join('/')
    }
  }
  return sections.join('/')
}

/**
 * Returns a path string where all `/:optional?` params are no longer optional.
 *
 * @param {String} path
 * @returns {String} path without optional params converted to required params
 */
const createRoutePathForOptionalParams = path => path.replace(/\?/g, '')

export const createV6RouteForV5OptionalParam = (path, Element) => (
  <Route path={createRoutePathForOptionalParams(path)} element={<Element />} />
)

export const createV6RouteForV5NoOptionalParam = (path, Element) => (
  <Route path={createRoutePathWithoutOptionalParams(path)} element={<Element />} />
)
