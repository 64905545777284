import GothamLight from 'Fonts/Gotham-Light.otf'
import GothamRegular from 'Fonts/Gotham-Book.otf'
import GothamSemiBold from 'Fonts/Gotham-Medium.otf'
import GothamBold from 'Fonts/Gotham-Bold.otf'
import breakpoints from './breakpoints'

export const fontWeight = {
  thin: 100,
  light: 200,
  regular: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 800,
  black: 900,
}

export const Gotham = [
  {
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: fontWeight.regular,
    src: `url(${GothamLight})`,
  },
  {
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: fontWeight.normal,
    src: `url(${GothamRegular})`,
  },
  {
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: fontWeight.semibold,
    src: `url(${GothamSemiBold})`,
  },
  {
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: fontWeight.bold,
    src: `url(${GothamBold})`,
  },
]

const typography = {
  // Use the system font instead of the default Roboto font.
  fontFamily: ['Gotham', '-apple-system', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
  titleLarge: {
    fontSize: 24,
    lineHeight: '24px',
    fontWeight: fontWeight.semibold,
    sm: {
      fontSize: 34,
    },
  },
  titleMedium: {
    fontSize: 25,
    lineHeight: 1,
    fontWeight: fontWeight.semibold,
  },
  title: {
    fontSize: 20,
    lineHeight: 1,
    fontWeight: fontWeight.semibold,
  },
  h1: {
    fontSize: 32, // 24pt
    lineHeight: '36px',
    fontWeight: fontWeight.bold,
  },
  h2: {
    fontSize: 24, // 18pt
    lineHeight: '30px',
    fontWeight: fontWeight.bold,
  },
  h3: {
    fontSize: 21, // 16pt
    lineHeight: '27px',
    fontWeight: fontWeight.bold,
  },
  h4Label: {
    fontSize: 18, // 13.5pt
    lineHeight: '23px',
    fontWeight: fontWeight.normal,
  },
  h4: {
    fontSize: 18, // 13.5pt
    lineHeight: '23px',
    fontWeight: fontWeight.semibold,
  },
  h5: {
    fontSize: 16, // 12pt
    lineHeight: '20px',
    fontWeight: fontWeight.semibold,
  },
  h6: {
    fontSize: 14, // 10.5pt
    lineHeight: '18px',
    fontWeight: fontWeight.semibold,
  },
  labelBold: {
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: fontWeight.bold,
  },
  body1: {
    fontSize: 16, // 12pt
    lineHeight: '22px',
    fontWeight: fontWeight.regular,
  },
  body1Normal: {
    fontSize: 16, // 12pt
    lineHeight: '22px',
    fontWeight: fontWeight.normal,
  },
  bodySmall: {
    fontSize: 14,
    lineHeight: '19px',
    fontWeight: fontWeight.regular,
    lg: {
      fontSize: 16,
      lineHeight: '22px',
    },
  },
  body2: {
    fontSize: 14, // 10.5pt
    lineHeight: '20px',
    fontWeight: fontWeight.normal,
  },
  titleSmall: {
    fontSize: 15,
    lineHeight: '15px',
    fontWeight: fontWeight.semibold,
  },
  subtitle1: {
    fontSize: 12, // 9pt
    lineHeight: '15px',
  },
  subtitle2: {
    fontSize: 10, // 7.5pt
    lineHeight: '13px',
  },
  button: {
    fontSize: 14, // 10.5pt
    lineHeight: '17px',
    letterSpacing: 0.5,
    fontWeight: fontWeight.semibold,
  },
  toggleSwitch: {
    fontSize: 11, // 8pt
    lineHeight: '13px',
    fontWeight: fontWeight.normal,
    [`@media(min-width: ${breakpoints.md}px)`]: {
      fontSize: 14, // 10.5pt
      lineHeight: '16px',
    },
  },
  h4Upper: {
    fontSize: 18, // 13.5pt
    lineHeight: '23px',
    fontWeight: fontWeight.semibold,
    letterSpacing: 1.13,
    textTransform: 'uppercase',
  },
  h5Upper: {
    fontSize: 16, // 12pt
    lineHeight: '20px',
    fontWeight: fontWeight.semibold,
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  h6Upper: {
    fontSize: 14, // 10.5pt
    lineHeight: '16px',
    fontWeight: fontWeight.semibold,
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  label: {
    fontSize: 10, // 7.5pt
    lineHeight: '13px',
    letterSpacing: 0.5,
    textTransform: 'uppercase',
    fontWeight: fontWeight.bold,
  },
  labelLarge: {
    fontSize: 16,
    lineHeight: '20px',
    letterSpacing: 1,
    fontWeight: fontWeight.normal,
  },
  subtitle: {
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: fontWeight.normal,
  },
  input: {
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: fontWeight.normal,
  },
  labelLargeBold: {
    fontSize: 16,
    lineHeight: 1,
    letterSpacing: 0.6,
    fontWeight: fontWeight.semibold,
  },
  labelLargeBoldResponsive: {
    fontSize: 14,
    lineHeight: 1,
    letterSpacing: 0.6,
    fontWeight: fontWeight.semibold,
    lg: {
      fontSize: 16,
    },
  },
  labelLargeBoldUpper: {
    fontSize: 16,
    lineHeight: 1,
    letterSpacing: 0.6,
    textTransform: 'uppercase',
    fontWeight: fontWeight.bold,
  },
  labelMedium: {
    fontSize: 14,
    lineHeight: 1,
    letterSpacing: 0.88,
    textTransform: 'uppercase',
    fontWeight: fontWeight.normal,
  },
  labelMediumBold: {
    fontSize: 14,
    lineHeight: 1,
    letterSpacing: 0.6,
    textTransform: 'uppercase',
    fontWeight: fontWeight.bold,
  },
  labelBasic: {
    fontSize: 14,
    lineHeight: 1,
    fontWeight: fontWeight.regular,
  },
  headerLarge: {
    fontSize: 30,
    lineHeight: '41px',
    fontWeight: fontWeight.bold,
  },
  headerNormal: {
    fontSize: 23,
    lineHeight: '31px',
    fontWeight: fontWeight.regular,
  },
  headerMedium: {
    fontSize: 23,
    lineHeight: '31px',
    fontWeight: fontWeight.semibold,
  },
  placeholder: {
    fontSize: 18,
    lineHeight: '28px',
    fontStyle: 'italic',
  },
  badge: {
    fontSize: 13,
    lineHeight: 1,
    fontWeight: fontWeight.bold,
  },
  tooltipLarge: {
    fontSize: 20,
    lineHeight: '28px',
    fontWeight: fontWeight.normal,
  },
  tooltip: {
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: fontWeight.normal,
  },
  head1: {
    fontSize: 20,
    lineHeight: 1,
    letterSpacing: 1.63,
    fontWeight: fontWeight.semibold,

    md: {
      fontSize: 30,
    },
  },
  subhead1: {
    fontSize: 16,
    lineHeight: 1,
    letterSpacing: 1.13,
    fontWeight: fontWeight.normal,

    md: {
      fontSize: 20,
    },
  },
  descriptionAtomic: {
    fontSize: 10,
    lineHeight: '12px',
    fontWeight: 400,
    marginBottom: '20px',
    fontFamily: '"SourceSansPro", "Helvetica Neue", "Arial"',
  },
  body3: {
    fontSize: 16,
    lineHeight: '16px',
    fontWeight: 300,
    fontFamily: 'SourceSansPro,-apple-system,"Helvetica Neue",Arial,sans-serif',
  },
}

export default typography
